var render = function render(){
  var _this = this;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "item-body marketing-container"
  }, [_c('div', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "header-mol"
  }, [_vm._m(0), _c('el-button', {
    attrs: {
      "type": "primary",
      "icon": "el-icon-plus"
    },
    on: {
      "click": function click($event) {
        return _vm.createDialog('');
      }
    }
  }, [_vm._v(" 创建活动 ")])], 1), _c('div', [_c('el-table', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "border": "",
      "data": _vm.marketActivityList
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "礼包信息"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "name"
        }, [_vm._v(_vm._s(scope.row.giftBagName))]), _c('div', {
          staticClass: "price"
        }, [_vm._v(" 定价："), _c('span', {
          staticStyle: {
            "color": "red"
          }
        }, [_vm._v(" ¥" + _vm._s(scope.row.giftBagPrice) + " ")])]), _c('div', {
          staticClass: "description"
        }, [_vm._v("说明：" + _vm._s(scope.row.description))])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "productName",
      "label": "产品/服务"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [!scope.row.marketActivityProductList.length ? _c('div', [_vm._v("-")]) : _c('ul', _vm._l(scope.row.marketActivityProductList, function (item, index) {
          return _c('li', {
            key: index
          }, [_vm._v(" • ¥" + _vm._s(item.productPrice) + " " + _vm._s(item.productName) + " ")]);
        }), 0)];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "updateUserName",
      "label": "更新人"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('div', [_vm._v(_vm._s(scope.row.updateUserName))]), _c('div', [_vm._v(_vm._s(scope.row.updateTime))])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "状态"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [parseInt(scope.row.status) ? _c('span', [_vm._v("已发布")]) : _c('span', {
          staticStyle: {
            "color": "red"
          }
        }, [_vm._v("草稿")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "address",
      "label": "操作"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [!scope.row.marketActivityProductList.length ? _c('el-button', {
          attrs: {
            "type": "text",
            "size": "small"
          },
          on: {
            "click": function click($event) {
              return _vm.createProductItem(scope.row);
            }
          }
        }, [_c('span', [_vm._v("创建产品/服务")])]) : _c('el-button', {
          attrs: {
            "type": "text",
            "size": "small"
          },
          on: {
            "click": function click($event) {
              return _vm.editGift(scope.row, scope.$index);
            }
          }
        }, [_c('span', [_vm._v("管理产品/服务")])]), _c('el-button', {
          attrs: {
            "type": "text",
            "size": "small"
          },
          on: {
            "click": function click($event) {
              return _vm.createDialog(scope.row);
            }
          }
        }, [_vm._v("编辑礼包")]), scope.row.marketActivityProductList.length ? _c('el-button', {
          attrs: {
            "type": "text",
            "size": "small"
          },
          on: {
            "click": function click($event) {
              return _vm.statusSwitch(scope.row.id, scope.row.status);
            }
          }
        }, [_vm._v(_vm._s(parseInt(scope.row.status) ? "下架" : "上架"))]) : _vm._e(), _c('el-popconfirm', {
          attrs: {
            "confirm-button-text": "好的",
            "cancel-button-text": "不用了",
            "icon": "el-icon-info",
            "icon-color": "red",
            "title": "确定删除吗？"
          },
          on: {
            "onConfirm": function onConfirm($event) {
              return _vm.marketActivityDelete(scope.row);
            }
          }
        }, [_c('el-button', {
          attrs: {
            "slot": "reference",
            "type": "text",
            "size": "small"
          },
          slot: "reference"
        }, [_vm._v(" 删除 ")])], 1)];
      }
    }])
  })], 1)], 1)]), _c('el-dialog', {
    staticClass: "dialog-con",
    attrs: {
      "title": _vm.marketActivityParams.id ? '编辑礼包' : '创建礼包',
      "visible": _vm.dialogVisible,
      "width": "500px",
      "before-close": function beforeClose() {
        return _this.dialogVisible = false;
      }
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c('el-form', {
    ref: "marketActivityForm",
    staticClass: "form",
    attrs: {
      "model": _vm.marketActivityParams,
      "label-width": "100px",
      "size": "small"
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "礼包名称:",
      "rules": [_vm.rules.required()],
      "prop": "giftBagName"
    }
  }, [_c('el-input', {
    attrs: {
      "maxlength": "20",
      "placeholder": "请输入，建议10个以内"
    },
    model: {
      value: _vm.marketActivityParams.giftBagName,
      callback: function callback($$v) {
        _vm.$set(_vm.marketActivityParams, "giftBagName", $$v);
      },
      expression: "marketActivityParams.giftBagName"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "礼包定价:",
      "rules": [_vm.rules.required(), _vm.rules.m0m89p2],
      "prop": "giftBagPrice"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.marketActivityParams.giftBagPrice,
      callback: function callback($$v) {
        _vm.$set(_vm.marketActivityParams, "giftBagPrice", $$v);
      },
      expression: "marketActivityParams.giftBagPrice"
    }
  }, [_c('span', {
    attrs: {
      "slot": "append"
    },
    slot: "append"
  }, [_vm._v("元")])])], 1), _c('el-form-item', {
    attrs: {
      "label": ""
    }
  }, [_c('div', {
    staticClass: "text",
    staticStyle: {
      "ine-height": "initial"
    }
  }, [_vm._v(" 如果客户参与该活动，则最终成交价=优惠总价+礼包价 ")])]), _c('el-form-item', {
    attrs: {
      "label": "礼包说明:"
    }
  }, [_c('div', {
    staticClass: "text"
  }, [_vm._v("礼包相关注意事项及说明，建议500字以内")]), _c('el-input', {
    attrs: {
      "type": "textarea",
      "placeholder": "请输入"
    },
    model: {
      value: _vm.marketActivityParams.description,
      callback: function callback($$v) {
        _vm.$set(_vm.marketActivityParams, "description", $$v);
      },
      expression: "marketActivityParams.description"
    }
  })], 1)], 1), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    attrs: {
      "size": "small"
    },
    on: {
      "click": function click($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("取 消")]), _c('el-button', {
    attrs: {
      "type": "primary",
      "size": "small"
    },
    on: {
      "click": _vm.saveOrUpdate
    }
  }, [_vm._v(" 确 定 ")])], 1)], 1), _c('el-dialog', {
    staticClass: "dialog-con",
    attrs: {
      "title": _vm.productServiceParams.id ? '编辑产品/服务' : '创建产品/服务',
      "visible": _vm.dialogProductVisible,
      "width": "500px",
      "before-close": function beforeClose() {
        return _this.dialogProductVisible = false;
      }
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogProductVisible = $event;
      }
    }
  }, [_c('el-form', {
    ref: "productServiceForm",
    attrs: {
      "model": _vm.productServiceParams,
      "label-width": "100px",
      "size": "small"
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "名称:",
      "rules": [_vm.rules.required()],
      "prop": "productName"
    }
  }, [_c('el-input', {
    attrs: {
      "maxlength": "20",
      "placeholder": "请输入，建议10个以内"
    },
    model: {
      value: _vm.productServiceParams.productName,
      callback: function callback($$v) {
        _vm.$set(_vm.productServiceParams, "productName", $$v);
      },
      expression: "productServiceParams.productName"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "价值:",
      "rules": [_vm.rules.required(), _vm.rules.m0m89p2],
      "prop": "productPrice"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.productServiceParams.productPrice,
      callback: function callback($$v) {
        _vm.$set(_vm.productServiceParams, "productPrice", $$v);
      },
      expression: "productServiceParams.productPrice"
    }
  }, [_c('span', {
    attrs: {
      "slot": "append"
    },
    slot: "append"
  }, [_vm._v("元")])])], 1), _c('el-form-item', {
    attrs: {
      "label": ""
    }
  }, [_c('div', {
    staticClass: "text",
    staticStyle: {
      "ine-height": "initial"
    }
  }, [_vm._v(" 如果客户参与该活动，则最终成交价=优惠总价+礼包价 ")])]), _c('el-form-item', {
    attrs: {
      "label": "说明:",
      "rules": [_vm.rules.required()],
      "prop": "description"
    }
  }, [_c('div', {
    staticClass: "text"
  }, [_vm._v("礼包相关注意事项及说明，建议500字以内")]), _c('el-input', {
    attrs: {
      "type": "textarea",
      "placeholder": "请输入"
    },
    model: {
      value: _vm.productServiceParams.description,
      callback: function callback($$v) {
        _vm.$set(_vm.productServiceParams, "description", $$v);
      },
      expression: "productServiceParams.description"
    }
  })], 1)], 1), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    attrs: {
      "size": "small"
    },
    on: {
      "click": function click($event) {
        _vm.dialogProductVisible = false;
      }
    }
  }, [_vm._v("取 消")]), _c('el-button', {
    attrs: {
      "type": "primary",
      "size": "small"
    },
    on: {
      "click": _vm.productSaveOrUpdate
    }
  }, [_vm._v("确 定")])], 1)], 1), _c('el-dialog', {
    staticClass: "dialog-con",
    attrs: {
      "title": "管理产品/服务",
      "visible": _vm.editProductVisible,
      "width": "390px",
      "before-close": function beforeClose() {
        return _this.editProductVisible = false;
      }
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.editProductVisible = $event;
      }
    }
  }, [_c('div', {
    staticClass: "edit-gift-dialog"
  }, [_c('div', {
    staticClass: "btn x1 aic",
    on: {
      "click": function click($event) {
        return _vm.createProductItem({
          id: _vm.productListId
        });
      }
    }
  }, [_vm._v(" + 添加礼包内容 ")]), _c('ul', {
    staticClass: "gift-list"
  }, _vm._l(_vm.productList, function (item, index) {
    return _c('li', {
      key: index,
      staticClass: "x3"
    }, [_c('div', {
      staticClass: "icon"
    }), _c('div', {
      staticClass: "right-con"
    }, [_c('div', {
      staticClass: "x3",
      staticStyle: {
        "height": "20px"
      }
    }, [_c('div', {
      staticClass: "title"
    }, [_vm._v(" ¥" + _vm._s(item.productPrice) + " " + _vm._s(item.productName) + " ")]), _c('div', {
      staticClass: "operation"
    }, [_c('span', {
      on: {
        "click": function click($event) {
          return _vm.createProductItem(item, 'edit');
        }
      }
    }, [_c('i', {
      staticClass: "el-icon-edit-outline"
    })]), _c('el-popconfirm', {
      attrs: {
        "confirm-button-text": "好的",
        "cancel-button-text": "不用了",
        "icon": "el-icon-info",
        "icon-color": "red",
        "title": "确定删除吗？"
      },
      on: {
        "onConfirm": function onConfirm($event) {
          return _vm.productDelete(item);
        }
      }
    }, [_c('span', {
      attrs: {
        "slot": "reference"
      },
      slot: "reference"
    }, [_c('i', {
      staticClass: "el-icon-delete"
    })])])], 1)]), _c('div', {
      staticClass: "description"
    }, [_vm._v(" " + _vm._s(item.description) + " ")])])]);
  }), 0)])])], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "info"
  }, [_c('h3', {
    staticClass: "title"
  }, [_vm._v("营销礼包")]), _c('p', {
    staticClass: "summary"
  }, [_vm._v("同一个活动，可支持创建多个礼包；")])]);

}]

export { render, staticRenderFns }