<template>
  <div class="item-body marketing-container">
    <div class="section">
      <div class="header-mol">
        <div class="info">
          <h3 class="title">营销礼包</h3>
          <p class="summary">同一个活动，可支持创建多个礼包；</p>
        </div>
        <el-button type="primary" icon="el-icon-plus" @click="createDialog('')">
          创建活动
        </el-button>
      </div>
      <div>
        <el-table border :data="marketActivityList" style="width: 100%">
          <el-table-column label="礼包信息">
            <template slot-scope="scope">
              <div class="name">{{ scope.row.giftBagName }}</div>
              <div class="price">
                定价：<span style="color: red">
                  ¥{{ scope.row.giftBagPrice }}
                </span>
              </div>
              <div class="description">说明：{{ scope.row.description }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="productName" label="产品/服务">
            <template slot-scope="scope">
              <div v-if="!scope.row.marketActivityProductList.length">-</div>
              <ul v-else>
                <li
                  v-for="(item, index) in scope.row.marketActivityProductList"
                  :key="index"
                >
                  • ¥{{ item.productPrice }} {{ item.productName }}
                </li>
              </ul>
            </template>
          </el-table-column>
          <el-table-column prop="updateUserName" label="更新人">
            <template slot-scope="scope">
              <div>{{ scope.row.updateUserName }}</div>
              <div>{{ scope.row.updateTime }}</div>
            </template>
          </el-table-column>
          <el-table-column label="状态">
            <template slot-scope="scope">
              <span v-if="parseInt(scope.row.status)">已发布</span>
              <span v-else style="color:red;">草稿</span>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="操作">
            <template slot-scope="scope">
              <el-button
                v-if="!scope.row.marketActivityProductList.length"
                type="text"
                size="small"
                @click="createProductItem(scope.row)"
              >
                <span>创建产品/服务</span>
              </el-button>
              <el-button
                @click="editGift(scope.row, scope.$index)"
                v-else
                type="text"
                size="small"
              >
                <span>管理产品/服务</span>
              </el-button>
              <el-button
                type="text"
                size="small"
                @click="createDialog(scope.row)"
                >编辑礼包</el-button
              >
              <el-button
                v-if="scope.row.marketActivityProductList.length"
                type="text"
                size="small"
                @click="statusSwitch(scope.row.id, scope.row.status)"
                >{{ parseInt(scope.row.status) ? "下架" : "上架" }}</el-button
              >
              <el-popconfirm
                confirm-button-text="好的"
                cancel-button-text="不用了"
                icon="el-icon-info"
                icon-color="red"
                title="确定删除吗？"
                @onConfirm="marketActivityDelete(scope.row)"
              >
                <el-button slot="reference" type="text" size="small">
                  删除
                </el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-dialog
      :title="marketActivityParams.id ? '编辑礼包' : '创建礼包'"
      :visible.sync="dialogVisible"
      width="500px"
      class="dialog-con"
      :before-close="() => (this.dialogVisible = false)"
    >
      <el-form
        :model="marketActivityParams"
        class="form"
        ref="marketActivityForm"
        label-width="100px"
        size="small"
      >
        <el-form-item
          label="礼包名称:"
          :rules="[rules.required()]"
          prop="giftBagName"
        >
          <el-input
            v-model="marketActivityParams.giftBagName"
            maxlength="20"
            placeholder="请输入，建议10个以内"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="礼包定价:"
          :rules="[rules.required(), rules.m0m89p2]"
          prop="giftBagPrice"
        >
          <el-input
            v-model="marketActivityParams.giftBagPrice"
            placeholder="请输入"
          >
            <span slot="append">元</span>
          </el-input>
        </el-form-item>
        <el-form-item label="">
          <div class="text" style="ine-height: initial;">
            如果客户参与该活动，则最终成交价=优惠总价+礼包价
          </div>
        </el-form-item>
        <el-form-item label="礼包说明:">
          <div class="text">礼包相关注意事项及说明，建议500字以内</div>
          <el-input
            v-model="marketActivityParams.description"
            type="textarea"
            placeholder="请输入"
          />
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="saveOrUpdate" size="small">
          确 定
        </el-button>
      </span>
    </el-dialog>

    <el-dialog
      :title="productServiceParams.id ? '编辑产品/服务' : '创建产品/服务'"
      :visible.sync="dialogProductVisible"
      width="500px"
      class="dialog-con"
      :before-close="() => (this.dialogProductVisible = false)"
    >
      <el-form
        :model="productServiceParams"
        ref="productServiceForm"
        label-width="100px"
        size="small"
      >
        <el-form-item
          label="名称:"
          :rules="[rules.required()]"
          prop="productName"
        >
          <el-input
            maxlength="20"
            v-model="productServiceParams.productName"
            placeholder="请输入，建议10个以内"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="价值:"
          :rules="[rules.required(), rules.m0m89p2]"
          prop="productPrice"
        >
          <el-input
            v-model="productServiceParams.productPrice"
            placeholder="请输入"
          >
            <span slot="append">元</span>
          </el-input>
        </el-form-item>
        <el-form-item label="">
          <div class="text" style="ine-height: initial;">
            如果客户参与该活动，则最终成交价=优惠总价+礼包价
          </div>
        </el-form-item>
        <el-form-item
          label="说明:"
          :rules="[rules.required()]"
          prop="description"
        >
          <div class="text">礼包相关注意事项及说明，建议500字以内</div>
          <el-input
            v-model="productServiceParams.description"
            type="textarea"
            placeholder="请输入"
          />
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogProductVisible = false" size="small"
          >取 消</el-button
        >
        <el-button type="primary" @click="productSaveOrUpdate" size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      title="管理产品/服务"
      :visible.sync="editProductVisible"
      width="390px"
      class="dialog-con"
      :before-close="() => (this.editProductVisible = false)"
    >
      <div class="edit-gift-dialog">
        <div
          class="btn x1 aic"
          @click="createProductItem({ id: productListId })"
        >
          + 添加礼包内容
        </div>
        <ul class="gift-list">
          <li class="x3" v-for="(item, index) in productList" :key="index">
            <div class="icon"></div>
            <div class="right-con">
              <div class=" x3" style="height:20px">
                <div class="title">
                  ¥{{ item.productPrice }} {{ item.productName }}
                </div>
                <div class="operation">
                  <span @click="createProductItem(item, 'edit')"
                    ><i class="el-icon-edit-outline"></i
                  ></span>
                  <el-popconfirm
                    confirm-button-text="好的"
                    cancel-button-text="不用了"
                    icon="el-icon-info"
                    icon-color="red"
                    title="确定删除吗？"
                    @onConfirm="productDelete(item)"
                  >
                    <span slot="reference"><i class="el-icon-delete"></i></span>
                  </el-popconfirm>
                </div>
              </div>
              <div class="description">
                {{ item.description }}
              </div>
            </div>
          </li>
        </ul>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  saveOrUpdate,
  getMarketActivity,
  productSaveOrUpdate,
  getProductList,
  productDelete,
  statusSwitch,
  marketActivityDelete
} from "@/api/marketActivity";
import { mapState } from "vuex";
import rules from "@/utils/rules";
export default {
  props: {
    checkActivity: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      rules,
      dialogVisible: false,
      dialogProductVisible: false,
      editProductVisible: false,
      marketActivityList: [],
      productList: [],
      productServiceParams: {
        description: "",
        marketId: "",
        productName: "",
        productPrice: ""
      },
      marketActivityParams: {
        activityId: "",
        description: "",
        estateId: "",
        giftBagName: "",
        giftBagPrice: ""
      }
    };
  },
  computed: {
    ...mapState({
      estateInfo: state => state
    }),
    estateId() {
      return this.$store.state.estateInfo.estateId;
    }
  },
  watch: {
    checkActivity() {
      this.getMarketActivity();
    }
  },
  methods: {
    createDialog(item) {
      if (item) {
        Object.assign(this.marketActivityParams, item);
      } else {
        this.marketActivityParams = {
          activityId: this.checkActivity,
          description: "",
          estateId: this.estateId,
          giftBagName: "",
          giftBagPrice: ""
        };
        this.$nextTick(() => {
          this.$refs.marketActivityForm.resetFields();
        });
      }

      this.dialogVisible = true;
    },
    /**
     * 删除营销活动
     */
    async marketActivityDelete({ id }) {
      let data = await marketActivityDelete(id);

      if (data) {
        this.getMarketActivity();
        this.$showSuccess("删除成功");
      }
    },
    async statusSwitch(id, status) {
      let data = await statusSwitch(id, { status: parseInt(status) ? 0 : 1 });
      if (data) {
        this.$showSuccess(parseInt(status) ? "下架成功" : "上架成功");
        this.getMarketActivity();
      }
    },
    /**
     * 获取列表活动id
     */
    createProductItem(data, type) {
      if (type === "edit") {
        this.productServiceParams = data;
      } else {
        this.productServiceParams = {
          description: "",
          marketId: data.id,
          productName: "",
          productPrice: ""
        };
        this.productListId = data.id;
        this.$nextTick(() => {
          this.$refs.productServiceForm.resetFields();
        });
      }
      this.dialogProductVisible = true;
    },
    editGift(data, index) {
      this.editProductVisible = true;
      this.productListId = data.id;
      this.marketActivityList[index].isDelete = true;
      this.getProductList();
    },
    async getProductList() {
      this.productList = await getProductList(this.productListId);
    },
    /**
     * 创建礼包
     */
    async saveOrUpdate() {
      this.$refs.marketActivityForm.validate(async valid => {
        if (!valid) return;
        this.marketActivityParams.activityId = this.checkActivity;

        let data = await saveOrUpdate(this.marketActivityParams);

        if (data) {
          this.$showSuccess("创建成功");

          this.dialogVisible = false;
          this.getMarketActivity();
        }
      });
    },
    /**
     * 创建产品/服务
     */
    async productSaveOrUpdate() {
      this.$refs.productServiceForm.validate(async valid => {
        let { id } = this.productServiceParams;
        if (!valid) return;
        let data = await productSaveOrUpdate(this.productServiceParams);

        if (data) {
          this.dialogProductVisible = false;

          this.$showSuccess(id ? "编辑成功" : "创建成功");
          this.productServiceParams = {
            description: "",
            marketId: "",
            productName: "",
            productPrice: ""
          };
          this.getProductList();
          this.getMarketActivity();
        }
      });
    },
    /**
     * 删除产品/服务
     */
    async productDelete(item) {
      let data = await productDelete(item.id);
      if (data) {
        this.getProductList();
        this.getMarketActivity();
      }
    },
    /**
     * 获取 礼包集合
     */
    async getMarketActivity() {
      this.marketActivityList = await getMarketActivity(this.checkActivity);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-popover__reference-wrapper {
    margin-left: 10px;
  }
}
.edit-gift-dialog {
  .btn {
    border: 1px dotted #eeeeee;
    height: 36px;
    border-radius: 3px;
    color: #3470fc;
  }
  .gift-list {
    margin-top: 10px;
    li {
      padding: 10px;
      border-radius: 5px;
      background: #f2f2f2;
      margin-bottom: 20px;
      min-height: 110px;
      &:hover {
        background: #ced3df;
        .operation {
          display: inline-block;
          font-size: 18px;
          span {
            display: inline-block;
            margin: 0 10px;
            cursor: pointer;
            &:first-child {
              color: #3470fc;
            }
            &:last-child {
              color: #ff1010;
            }
          }
        }
      }
      .operation {
        display: none;
      }
      &:last-child {
        margin-bottom: 0;
      }
      .right-con {
        width: 305px;
      }
      .title {
        font-weight: bold;
        font-size: 15px;
        color: #333;
      }
      .description {
        font-size: 13px;
        color: #666;
        margin-top: 5px;
      }
    }
  }
}

.dialog-con {
  .text {
    font-size: 13px;
    color: #999;
  }
}

.item-body {
  background-color: #ffffff;
  .header {
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 20px;
    .activity-select {
      width: 300px;
      ::v-deep {
        .el-input {
          .el-input__inner {
            border: none;
            font-size: 16px;
            color: #3470fc;
          }
        }
      }
    }
  }

  .section {
    .header-mol {
      padding-left: 20px;
      margin-bottom: 20px;
    }
  }
}
</style>
